.btn-label {
    width: 80%;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.11;
    letter-spacing: -0.3px;
    text-align: center;
    margin-top: 10px;
    margin-left: 0;
    cursor: pointer;
  }

  i:not(bc-paginator i) {
    border: solid #292929;;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;

  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  }

  body{
    background-color: #e6e7e8;
  }

  .mat-datepicker-content .mat-calendar {
    width: 296px !important;
    height: 380px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2C2A29 !important;
}
.mat-select-arrow { visibility: hidden; }
.mat-select {width: 95% !important;}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #58595B;
}
.mat-calendar-body-selected {
  background-color: #FDDA24;
  color: #2C2A29;
}

.cdk-global-overlay-wrapper {
  opacity: 1;
  backdrop-filter: blur(10px) !important;
}

.mat-dialog-container {
  padding: 15px !important;
}
