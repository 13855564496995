
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/css/bancolombia-colors.css";

@import 'https://library-sdb.apps.bancolombia.com/bds/6.40.2/bds.min.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body{
    min-width: 470px;
}
.info-box{
    background-color: #7e7f80;
    color: #FFF;
    border-radius: 7px 0px 0px 7px;
    font-size: 9pt;
    text-align: center;
    position: fixed;
    right: 0%;
    @include item-box-size();
}
.info-box:hover{
    background-color: #b0b2b4;
}

.page-margin-container-flat{
    margin-left: auto;
    margin-right: auto;
    width: 850px;
    border-radius:4px;
}
.page-margin-container-thick{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 90vh;
}

.head-info-container{
  margin-left: auto;
  margin-right: auto;
    color: #7e7f80;
}


.generic-table{
      width: 100%;
    overflow: auto;
    margin-left: auto;
    margin-right:auto;

    border-radius: 3px;
    min-width: 300px;
    max-height: 500px;
    width: 100% !important;
}
.totals-table{
      width: 100%;
    overflow: auto;
    margin-left: auto;
    margin-right:auto;

    border-radius: 3px;
    min-height: 20px;
    max-height: 20px;
}
tr{
    line-height: 45px;
}

.header-background{
    background-color: #FFF;
    color: black;
    border-radius:2px;

}
.header-items-background{
    color: black;
    border-radius:2px;
    font-size: 10pt;
}
.line{
    border:solid 1px #7e7f80 ;
}
.cursorPointer, .info-box ,.arrowNew{
  cursor: pointer;
}

.borderBottom{
  width: 6%;
  right: 25%;
  position: relative;
  border-bottom: 1px solid;
  height: 20px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: -0.3px;
  text-align: center;
}

.margin, .buttonClose, .borderBottom{
  margin:10px;
}

.buttonClose{
  width: auto;
  left: 45%;
  position: relative;
  bottom: 37px;
  border-bottom: 1px solid;
  height: 19px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: -0.3px;
  text-align: center;
}

.btndownxlsx {
  height: 36px;
  width: 158px;
  border-radius: 10000px;
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  border: 1px solid transparent;
  border-color: #2C2A29;
}
.letter-downxls {
  height: 24px;
  width: 266.5px;
  color: #2C2A29;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: -0.38px;
  line-height: 24px;
  padding-left: 16px;
}
.arrowNew{
  margin-left: auto;
  width: 17.8px;
}
.letter-header-details {
  height: 64px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: -0.3px;
  background:#F4F4F4;
  color: #2C2A29;
  border-bottom: 1px solid #2C2A29;
}

.text-center{
  text-align: center;
}
.positionAvPage{
  width: 28em;
  left: 41em;
}
.fontBold, .buttonClose, .borderBottom{
  font-weight: bold;
}
.positionAbsolute {
  position: absolute;
}

.openSans{
  font-family: 'Open Sans';
}
.cssToolTip span, .cssToolTip2 span, .cssToolTip2 label {
  background-color: #2C2A29;
  box-shadow: 3px 3px 3px #333;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  color: #ffffff;
  display: none;
  padding: 10px;
  position: absolute;
  z-index: 100;
  width: 141px;
  margin-left: -77px;
}
.cssToolTip2 span, .cssToolTip2 label {
  font-size: 14px !important;
  width: 200px !important;
  height: 122px !important;
  margin-left: -100px !important;
  text-align: left !important;
  letter-spacing: -0.2px !important;
}
.iconX {
  cursor: pointer;
  margin-right: 20px;
  float: right;
  width: 20px;
}
.hide{
  display:none;
}

.center {
  justify-content: center;
}
.detailcredittable {
  width: 100%;
  border-radius: 5px;
  overflow: auto;
}

.totals-table{
  @include table-size();
  min-height: 20px;
  max-height: 20px;
}
tr{
  line-height: 45px;
}
